import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree,} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from "keycloak-angular";
import {environment} from "../../../environments/environment";


@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
    constructor(
        protected readonly router: Router,
        protected readonly keycloak: KeycloakService,
    ) {
        super(router, keycloak);
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        // Check if the user is authenticated using KeycloakAuthGuard.
        const isKeycloakAuthenticated = await super.canActivate(route, state);

        if (!isKeycloakAuthenticated) {
            return false;
        }

        // Get the roles required from the route.
        const requiredRoles = route.data.roles;
        // Allow the user to proceed if no additional roles are required to access the route.
        if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
            return true;
        }

        // Check if the user has the required roles.
        const userRoles = this.keycloak.getUserRoles();
        const authorized = requiredRoles.some((role) => userRoles.includes(role));
        if (!authorized) {
            // Handle the case when the user doesn't have the required roles.
            // You can redirect them to different routes based on their roles.
            if (userRoles.includes('admin')) {
                await this.router.navigate(['/privado/dashboard']); // Redirect admin to the dashboard
            } else if (userRoles.includes('user')) {
                await this.router.navigate(['/privado/process']); // Redirect user to the cockpit
            }
        }

        return authorized;
    }

    async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return this.canActivate(childRoute, state);
    }

    public async isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        // Force the user to log in if currently unauthenticated.
        if (!this.authenticated) {
            await this.keycloak.login({
                redirectUri: window.location.origin + environment.baseHref + state.url,
            });
        }

        // Get the roles required from the route.
        const requiredRoles = route.data.roles;
        // Allow the user to  proceed if no additional roles are required to access the route.
        if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
            return true;
        }

        // Allow the user to proceed if any of the the required roles are present.
        let authorized = requiredRoles.some((role) => this.roles.includes(role));
        if (!authorized) {
            // this.snackbarService.exibirMensagemErro('Você não tem permissão para acessar esta página.', '🔒');
        }
        return authorized ? authorized : this.router.parseUrl('/privado');
    }
}
