import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  blackListMessages = ['Funcionário já cadastrado.']

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          location.reload();
        }

        if (err.url && err.url.includes('/sso/login')) {
          location.reload();
        }


        if (!environment.production) {
          console.error('==>', err.error || err.statusText);
        }

        if (err.status === 500) {
          if ((typeof err.error) === "string" && !this.blackListMessages.includes(JSON.parse(err.error).mensagem)) {

          } else if (!this.blackListMessages.includes(err.error.mensagem)) {
          }
        }

        return throwError(err);
      }),
    );
  }
}
