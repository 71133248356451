import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'readableProcessDefinition',
  pure: true,
  standalone: true
})
export class ReadableProcessDefinitionPipe implements PipeTransform {
  transform(value: string, formatOption: 'versionkey' | 'key' | 'none' = 'versionkey'): string {
    // Split the input value based on ':'
    const parts = value.split(':');

    if (parts.length === 3) {
      const key = parts[0];
      const version = parts[1];

      // Choose the transformation option
      switch (formatOption) {
        case 'versionkey':
          return `${version} - ${key}`;
        case 'key':
          return key;
        default:
          return value;
      }
    }

    // Return the original value if the input format is not as expected
    return value;
  }
}
