import {APP_INITIALIZER, NgModule} from '@angular/core';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {environment} from '../../../environments/environment';

const factory = (keycloakService: KeycloakService): () => Promise<boolean> => async (): Promise<boolean> =>
        await keycloakService.init({
            config: {
                url: environment.keycloakUrl,
                realm: environment.keycloakRealm,
                clientId: environment.keycloakClientId,
            },
            bearerExcludedUrls: [
                'https://viacep.com.br',
                '/rest/geral/arquivo/download'
            ],
            initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                    window.location.origin + environment.baseHref + '/assets/silent-check-sso.html',
            },
        });

@NgModule({
    imports: [
        KeycloakAngularModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: factory,
            multi: true,
            deps: [KeycloakService],
        }
    ],
})
export class KeycloakModule {}
