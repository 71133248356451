import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {LOCALE_ID, NgModule} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, RouterModule, Routes} from '@angular/router';
import {ToastrModule} from "ngx-toastr";

import {AppComponent} from './app.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HttpErrorInterceptor} from "./config/interceptors/http-error.interceptor";
import {KeycloakModule} from "./config/keyclock/keycloak.module";
import {AuthGuard} from "./config/guards/auth.guard";
import {MatStepperModule} from "@angular/material/stepper";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {ReadableProcessDefinitionPipe} from "./utils/pipe/readable-process-definition.pipe";
import {MatSnackBarModule} from "@angular/material/snack-bar";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./entities/publico/publico.module').then(x => x.PublicoModule)
  },
  {
    path: 'publico',
    loadChildren: () => import('./entities/publico/publico.module').then(x => x.PublicoModule)
  },
  {
    path: 'privado',
    loadChildren: () => import('./entities/privado/privado.module').then(x => x.PrivadoModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['admin','user']
    },
  },

]

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    ToastrModule.forRoot(),
    MatDialogModule,
    MatButtonModule,
    KeycloakModule,
    HttpClientModule,
    MatStepperModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    MatSnackBarModule

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    ReadableProcessDefinitionPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
