export const environment = {
  production: true,
  baseHref: '/',
  baseApi: '/api',
  keycloakUrl: '/auth',
  keycloakRealm: 'velox',
  keycloakClientId: 'velox-auth',
  rest: '/rest',
  camundaUrl: '/bpm',
  dashboardUrl: '/analytics/public/dashboard/ff1d5715-0f68-424e-974f-5ff39734581f',
  biUrl:'/analytics'

};
